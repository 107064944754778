import * as React from 'react'
import './phone.css'
import * as moment from "moment"

// https://codepen.io/Wujek_Greg/pen/LmrweG

export default function Phone(props){
  return (
    <div className="phone-container">
      <div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="phone">
        <div className="phone-back">
          <div className="phone-left-side">
            <div className="phone-antena"></div>
            <div className="phone-button top"></div>
            <div className="phone-button"></div>
            <div className="phone-button bottom"></div>
            <div className="phone-antena bottom"></div>
          </div>
          <div className="phone-bottom">
            <div className="phone-antena"></div>
            <div className="bottom-speaker">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="phone-screw">
              <div></div>
            </div>
            <div className="phone-charger">
            </div>
            <div className="phone-screw right">
              <div></div>
            </div>
            <div className="bottom-speaker right">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="phone-antena right"></div>
          </div>
        </div>
        <div className="phone-screen"></div>
        <div className="phone-display">
          <div className="phone-notch">
            <div className="phone-speaker"></div>
          </div>
          <div className="display-content">
            <div className="notifications-bar">
              <div className="time">{moment().format("HH:mm")}</div>
              <div className="range"></div>
              <div className="wifi"></div>
              <div className="battery"></div>
            </div>
            <div className="nav">
              <div className="messages"></div>
              <div className="phone-number">{props.phoneNumber}</div>
              <div className="menu"></div>
            </div>
            <div className="current-plan">
              <div className="plan">
                <div>
                  <div className="plan-header">{props.plan}</div>
                  <div className="plan-price"><span>{props.planCurrency}</span>{props.planPrice}</div>
                </div>
                <div className="plan-date">
                  <div>{props.planDateOne}</div>
                  <div>{props.planDateTwo}</div>
                </div>
              </div>
              <div className="text-question">{props.spendings}<br />{props.question}</div>
            </div>
            <div className="plan-box">
              <div className="plan-options">
                <div>
                  <div>{props.numberOne}<span>{props.numberOneUnit}</span></div>
                  <div>{props.subscription}</div>
                </div>
                <div>
                  <div>{props.numberTwo}<span>{props.numberTwoUnit}</span></div>
                  <div>{props.location}</div>
                </div>
              </div>
              <div className="plan-text"><strong>{props.highLightFooter}</strong>{props.nonHighLightFooter}</div>
              <a className="change-plan" href="/">{props.buttonText}</a>
            </div>
            <div className="plan-list">
              <div className="plan-limit">
                <div className="limit-text">
                  <div><strong>{props.remainingSum}</strong><span>{props.remainingTime}</span></div>
                  <span className="limit-subtext">{props.remainingSubText}</span>
                </div>
                <div className="limit-icon"></div>
              </div>
              <div className="plan-limit">
                <div className="limit-text">
                  <div><strong>{props.pricePerSms}</strong><span>{props.perSmsText}</span></div>
                  <span className="limit-subtext">{props.removeLimit}</span>
                </div>
                <div className="limit-icon upgrade"></div>
              </div>
            </div>
            <div className="bottom-icons">
              <div className="chart"><div></div></div>
              <div className="eye"><div></div></div>
              <div className="equalizer"><div></div><div></div><div></div></div>
            </div>
            <div className="home-button"></div>
          </div>
        </div>
        <div className="phone-reflections">
          <div className="reflection-1"></div>
          <div className="reflection-2"></div>
          <div className="reflection-3"></div>
          <div className="reflection-4"></div>
          <div className="reflection-5"></div>
          <div className="reflection-6"></div>
          <div className="reflection-7"></div>
        </div>
      </div>
    </div>
  )
}
